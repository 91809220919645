import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { AbilityModule } from '@casl/angular';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SparkModule } from 'src/@spark/spark.module';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatOptionModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { NoAccessModule } from '../auth-pages/no-access/no-access.module';
import { DirectivesModule } from 'src/@spark/directives/directives.module';
@NgModule({
        imports: [
                CommonModule,
                MatFormFieldModule,
                MatInputModule,
                FormsModule,
                ReactiveFormsModule,
                TranslocoModule,
                AbilityModule,
                MatIconModule,
                MatProgressSpinnerModule,
                NgxSpinnerModule,
                RouterModule,
                MatDividerModule,
                FlexLayoutModule,
                SparkModule,
                MatOptionModule,
                MatSelectModule,
                MatTableModule,
                MatPaginatorModule,
                MatSlideToggleModule,
                NoAccessModule,
                MatCardModule,
                NgxUiLoaderModule,
                MatDialogModule,
                DirectivesModule
        ],
        exports: [
                CommonModule,
                FormsModule,
                MatFormFieldModule,
                MatInputModule,
                MatButtonModule,
                ReactiveFormsModule,
                TranslocoModule,
                AbilityModule,
                MatIconModule,
                MatProgressSpinnerModule,
                NgxSpinnerModule,
                RouterModule,
                MatDividerModule,
                FlexLayoutModule,
                MatOptionModule,
                MatSelectModule,
                MatTableModule,
                MatPaginatorModule,
                MatSlideToggleModule,
                NoAccessModule,
                MatCardModule,
                NgxUiLoaderModule,
                MatDialogModule,
                DirectivesModule
        ]
})
export class SharedModule {
}
